import React from "react"
import { Helmet } from "react-helmet"
import Wizard from "../components/wizard/Wizard"
import { Router } from "@reach/router"

const ObjednatPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Nákupní košík</title>
    </Helmet>

    <Router>
      <Wizard path="/kosik/:step" />
      <Wizard path="/kosik" />
    </Router>
  </>
)

export default ObjednatPage
