import React, { useEffect, useContext } from "react"
import Packeta from "../../../static/packeta"
import axiosInstance from "../../client"
import PageContext from "../../context/PageContext"
import { Error } from "../form/Error"

export const Zasilkovna = ({
  checked,
  register,
  errors,
  setValue,
  summary,
  tran,
}) => {
  //    let Packeta
  //    Packeta = require("../../../static/packeta.js")

  const [context, dispatch] = useContext(PageContext)
  const cart = context?.cart
  const cartId = cart?.cart?.cartHash
  const transportNote = cart?.cart?.transport_note

  function updateNote(id, name) {
    setValue("packetaId", id)
    let data
    if (id) {
      data = {
        transport_note: {
          type: "zasilkovna",
          id: id,
          name: name,
        },
      }
    } else {
      data = {
        transport_note: null,
      }
    }
    axiosInstance
      .post(
        "cart/transport_update/" + cartId + "/?transport=" + tran.transport_id,
        data
      )
      .then(res => {
        dispatch({ type: "SET_ITEMS", data: res.data })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  function showSelectedPickupPoint(point) {
    if (point) {
      const name = point.name
      updateNote(point.id, name)
    } else {
      updateNote()
    }
  }

  useEffect(
    () => {
      //console.log(checked);
    },
    // eslint-disable-next-line
    [checked]
  )

  useEffect(
    () => {
      cart &&
        !transportNote &&
        Packeta.Widget.pick("b4f511f1c6fbe0bd", showSelectedPickupPoint)
    },
    // eslint-disable-next-line
    []
  )

  return (
    <>
      <div className="p-2 px-4 bg-white mt-2 text-sm">
        {transportNote ? (
          <div className={"space-x-2 flex " + (summary && "text-xs")}>
            <span>{transportNote?.name}</span>
            {!summary && (
              <a
                className="underline hover:no-underline"
                onClick={() =>
                  Packeta.Widget.pick(
                    "9593ba4489aee1c9",
                    showSelectedPickupPoint
                  )
                }
              >
                Změnit
              </a>
            )}
          </div>
        ) : (
          <div>
            <a
              className="underline hover:no-underline"
              onClick={() =>
                Packeta.Widget.pick("b4f511f1c6fbe0bd", showSelectedPickupPoint)
              }
            >
              Vybrat výdejní místo
            </a>
          </div>
        )}

        {!summary && (
          <>
            <input
              type="hidden"
              {...register("packetaId", { required: true })}
              defaultValue={transportNote?.id}
            />
            {errors.packetaId?.type === "required" && !transportNote?.id && (
              <Error text={"Zvolte prosím výdejní místo"} />
            )}
          </>
        )}
      </div>
    </>
  )
}
