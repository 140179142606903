import React, { useContext, useEffect, useState } from "react"
import { RadioGroup } from "@headlessui/react"
import PageContext from "../../context/PageContext"
import axiosInstance from "../../client"
import { FaWallet } from "react-icons/fa"
import { ChevronDownIcon } from "@heroicons/react/solid"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const Payment = ({ paymentId, payments, summary }) => {
  const [selected, setSelected] = useState(paymentId)

  const [context, dispatch] = useContext(PageContext)
  const cart = context?.cart
  const cartId = cart?.cart?.cartHash

  function setPayment(e) {
    setSelected(e)
    axiosInstance
      .get("cart/payment_update/" + cartId + "/?payment=" + e)
      .then(res => {
        dispatch({ type: "SET_ITEMS", data: res.data })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(
    () => {
      paymentId && setSelected(paymentId)
    },
    // eslint-disable-next-line
    [paymentId]
  )

  return (
    <>
      <div
        className={
          "p-5  transition-all ease-in-out duration-300  " +
          (selected ? " my-2 py-2" : "bg-sky-600 bg-opacity-10 my-6 ")
        }
      >
        <RadioGroup
          value={selected}
          onChange={setPayment}
          className="max-w-3xl mx-auto"
        >
          <RadioGroup.Label className="sr-only">Platba</RadioGroup.Label>
          <div className="flex items-center w-full justify-between py-2">
            {selected ? (
              <>
                <div>Způsob platby</div>
              </>
            ) : (
              <>
                <div className="flex space-x-4 items-center font-bold text-sky-800">
                  <FaWallet className="w-6 h-6  " />
                  <span>Zvolte způsob platby</span>
                </div>
              </>
            )}
          </div>
          <div className="relative bg-white rounded-md -space-y-px transition-all ease-in-out duration-300 ">
            {payments &&
              payments.length > 0 &&
              payments.map((paym, paymIdx) => (
                <RadioGroup.Option
                  key={paym.payment_id}
                  value={parseInt(paym.payment_id)}
                  className={({ checked }) =>
                    classNames(
                      paymIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                      !summary && "cursor-pointer",
                      summary && "rounded-bl-md rounded-br-md",
                      paymIdx === payments.length - 1 && !selected
                        ? "rounded-bl-md rounded-br-md"
                        : "",
                      checked
                        ? summary
                          ? "bg-gray-100 rounded-md"
                          : "bg-sky-600 bg-opacity-10 rounded-b-none border-blue-600 z-10 rounded-md"
                        : !selected
                        ? "border-gray-200  "
                        : "hidden",
                      "relative border p-4 flex-col md:pl-4 md:pr-6 grid  grid-cols-3 focus:outline-none hover:bg-gray-100 transition-all ease-in-out duration-300 "
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div
                        className="flex items-center text-sm col-span-2"
                        onClick={() => checked && setPayment(null)}
                      >
                        <span
                          className={classNames(
                            checked
                              ? "bg-sky-700 border-transparent"
                              : "bg-white border-gray-300",
                            summary && "hidden",
                            active ? "ring-2 ring-offset-2 ring-sky-700" : "",
                            "h-4 w-4 rounded-full border flex items-center justify-center"
                          )}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <RadioGroup.Label
                          as="span"
                          className={classNames(
                            checked ? "text-gray-900" : "text-gray-900",
                            "ml-3 font-medium"
                          )}
                        >
                          {paym.payment_name}
                        </RadioGroup.Label>
                      </div>
                      <RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 text-right">
                        <span
                          className={classNames(
                            checked ? "text-gray-900" : "text-gray-900",
                            "font-medium"
                          )}
                        >
                          {paym.payment_price_vat.toLocaleString()} Kč
                        </span>{" "}
                      </RadioGroup.Description>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            {!summary && selected && (
              <button
                onClick={() => setPayment(null)}
                className="w-full bg-gray-50 rounded-b-md px-5 py-2 text-sm text-left border text-gray-500 hover:bg-gray-100 space-x-2 flex items-center "
              >
                <ChevronDownIcon className="w-5 h-5" />{" "}
                <span>Změnit způsob platby</span>
              </button>
            )}
          </div>
        </RadioGroup>
      </div>
    </>
  )
}
