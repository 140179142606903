import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form";
import axiosInstance from "../../client";
import PageContext from "../../context/PageContext";
import { Error } from "../form/Error"; 
import { Loading } from "../layout/Loading";


export const Coupon = () => {
    const [promo, setPromo] = useState(false);
    const [context, dispatch] = useContext(PageContext);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    let cartId = context.cart?.cart?.cartHash;
 
     const { register, handleSubmit, formState: { errors }  } = useForm({
        mode: "onBlur"
      });  
    
      const onSubmit = async data => { 
        setLoading(true); 
           axiosInstance.get('cart/promo_code/' + cartId + '/?coupon=' + data.coupon)
            .then(res => {
              dispatch({type:'SET_ITEMS', data:res.data}) 
              setLoading(false); 
            })
            .catch(function (error) {
                console.log(error);
                setErrorMessage(error?.response?.data?.message)
                setLoading(false); 
            });
        
      }; 

    return (
        <>
                <div role="button" tabIndex={0} className="inline-flex items-center sm:col-span-4 cursor-pointer w-auto ml-2 lg:mt-4 mr-2" onClick={() => setPromo(!promo)} onKeyDown={() => setPromo(!promo)}>
                    <button  
                    type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                    className={(promo ? 'bg-amber-500' : 'bg-gray-300' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                    >
                        <span className="sr-only">mám slevový kód</span>
                        <span aria-hidden="true" 
                        className={(promo ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                        </span>
                    </button>
                    <span className={(promo ? 'text-gray-700 font-bold' : 'text-gray-400' ) + " text-sm flex space-x-2 ml-3"} id="toggleLabel">
                        <span >mám slevový kód</span>                                   
                    </span>
                </div>

                {promo && 
                    <>

                    <form  onSubmit={handleSubmit(onSubmit)}  > 
                 
                    <div>
                       
                        <div className="mt-1 p-2 flex rounded-md ">
                            <div className="relative flex  focus-within:z-10">
                            
                            <input
                                type="text"
                                {...register("coupon", { required: true })}
                                name="coupon"
                                id="coupon" 
                                className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-md pl-3 sm:text-sm border-gray-300"
                                placeholder="Slevový kód"
                            />
                            </div>
                            <button
                            type="submit"
                            className="  relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm ml-1 rounded-md bg-gray-600 hover:bg-gray-700 focus:outline-none text-white"
                            >
                            <span>Vložit</span>
                            </button>
                        </div>
                        {errors.coupon && 
                            <Error text={'Vložte slevový kód'} />
                        }

                        {errorMessage && <Error text={errorMessage} />}
                    </div> 
              
                  
                    </form>  
                    </>
                }

                <Loading loading={loading} /> 
        </>
    )
}