import React, { useEffect, useContext, useState } from "react"
import axiosInstance from "../../client"
import PageContext from "../../context/PageContext"
import { Error } from "../form/Error"
import { ModalLayout } from "../layout/modal"
import { format } from "date-fns"
import { cs } from "date-fns/locale"
import { CheckIcon } from "@heroicons/react/solid"
import { Loading } from "../layout/Loading"

function getPreviousMonday(date) {
  var day = date.getDay()
  var prevMonday = new Date()
  if (date.getDay() == 0) {
    prevMonday.setDate(date.getDate() - 6)
  } else {
    prevMonday.setDate(date.getDate() - (day - 1))
  }

  return prevMonday
}

function previousDay(date, count) {
  date.setDate(date.getDate() - count)
  return date
}

function nextDay(date) {
  date.setDate(date.getDate() + 1)
  return date
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const Rozvoz = ({
  checked,
  register,
  errors,
  setValue,
  summary,
  tran,
}) => {
  const [context, dispatch] = useContext(PageContext)
  const [loading, setLoading] = useState(false)
  const cart = context?.cart
  const cartId = cart?.cart?.cartHash
  const transportNote = cart?.cart?.transport_note

  //let today = new Date("October 02, 2022")
  let today = new Date()
  let todayFormatted = format(today, "yyyy-MM-dd")
  let monday
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  let hour = parseInt(format(today, "k"))
  let selectedDay = transportNote?.date
    ? new Date(transportNote?.date)
    : hour < 8
    ? today
    : format(tomorrow, "i") === "6"
    ? new Date().setDate(today.getDate() + 3)
    : format(tomorrow, "i") === "7"
    ? new Date().setDate(today.getDate() + 2)
    : tomorrow

  function getDays() {
    let dates = []
    let isToday = false
    let day
    let dayOfWeek = format(today, "i")
    let weekBefore
    let total = 29
    let isHoliday

    monday = getPreviousMonday(today)

    if (format(monday, "yyyy-MM-dd") === todayFormatted) {
      // pondeli je dnes, pridame jeste jeden tyden pred
      weekBefore = previousDay(monday, 7)
      dates.push({
        date: format(weekBefore, "yyyy-MM-dd"),
        isToday: false,
        visible: false,
        selectable:
          format(weekBefore, "i") === "6" || format(weekBefore, "i") === "7"
            ? false
            : true,
      })
      for (var i = 0; i < 7; i++) {
        day = nextDay(monday)
        isToday = false
        if (format(day, "yyyy-MM-dd") === todayFormatted) {
          isToday = true
        }
        isHoliday = false
        if (format(day, "i") === "7") {
          isHoliday = true
        }
        dates.push({
          date: format(day, "yyyy-MM-dd"),
          isToday: isToday,
          visible: isToday ? true : false,
          isHoliday: isHoliday,
          selectable:
            format(day, "i") === "6" || format(day, "i") === "7"
              ? false
              : isToday && hour <= 8
              ? true
              : false,
        })
      }
      total = 22
    } else {
      // jiny den v tydnu

      dates.push({ date: format(monday, "yyyy-MM-dd"), isToday: false })
      for (var i = 0; i < dayOfWeek; i++) {
        day = nextDay(monday)
        if (format(day, "yyyy-MM-dd") === todayFormatted) {
          isToday = true
        }
        isHoliday = false
        if (format(day, "i") === "7") {
          isHoliday = true
        }
        dates.push({
          date: format(day, "yyyy-MM-dd"),
          isToday: isToday,
          visible: isToday ? true : false,
          isSelected: selectedDay
            ? format(day, "yyyy-MM-dd") === format(selectedDay, "yyyy-MM-dd")
              ? true
              : false
            : false,
          isHoliday: isHoliday,
          selectable:
            format(day, "i") === "6" || format(day, "i") === "7"
              ? false
              : isToday && hour <= 8
              ? true
              : false,
        })
        monday = day
        if (format(monday, "yyyy-MM-dd") === todayFormatted) {
          break
        }
      }
    }

    for (var i = 1; i < total - dayOfWeek; i++) {
      day = nextDay(day)
      isHoliday = false
      if (format(day, "i") === "7") {
        isHoliday = true
      }
      dates.push({
        date: format(day, "yyyy-MM-dd"),
        isToday: false,
        visible: true,
        isCurrentMonth: true,
        isSelected: selectedDay
          ? format(day, "yyyy-MM-dd") === format(selectedDay, "yyyy-MM-dd")
            ? true
            : false
          : false,
        isHoliday: isHoliday,
        selectable:
          format(day, "i") === "6" || format(day, "i") === "7" ? false : true,
      })
    }

    let holidayDates = []
    let newDate = {}
    let publicHolidays = cart?.cart?.public_holidays

    dates.map(day => {
      if (selectedDay && day.date === format(selectedDay, "yyyy-MM-dd")) {
        selectedDay = false
      }
      isHoliday = publicHolidays.find(element => element === day.date)
        ? true
        : day.isHoliday

      newDate = {
        ...day,
        isHoliday: isHoliday,
        selectable: isHoliday ? false : day.selectable,
        isSelected: isHoliday ? false : day.isSelected,
      }
      holidayDates.push(newDate)
    })

    return holidayDates
  }
  const days = getDays()

  useEffect(
    () => {
      cart && !transportNote && chooseDate()
    },
    // eslint-disable-next-line
    []
  )

  function updateNote(dateFormat) {
    let date = new Date(dateFormat)
    let name = format(date, "d. M. yyyy") + ", čas rozvozu: 16 - 20 h"
    setValue("rozvozDate", name)
    let data
    if (date) {
      data = {
        transport_note: {
          type: "Rozvoz",
          name: name,
          date: date,
        },
      }
    } else {
      data = {
        transport_note: null,
      }
    }
    dispatch({ type: "CLEAR_MODAL" })
    setLoading(true)
    axiosInstance
      .post(
        "cart/transport_update/" + cartId + "/?transport=" + tran.transport_id,
        data
      )
      .then(res => {
        dispatch({ type: "SET_ITEMS", data: res.data })
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const DateSelect = () => {
    return (
      <ModalLayout title={"Datum dodání"}>
        {loading && <Loading loading={true} />}
        <div className="mx-auto max-w-xs">
          <div className="flex items-center">
            <h2 className="flex-auto font-semibold text-gray-900 first-letter:uppercase">
              {format(new Date(), "LLLL yyyy", { locale: cs })}
            </h2>
          </div>
          <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
            <div>Po</div>
            <div>Út</div>
            <div>St</div>
            <div>Čt</div>
            <div>Pá</div>
            <div>So</div>
            <div>Ne</div>
          </div>
          <div className="mt-2 grid grid-cols-7 text-sm">
            {days.map((day, dayIdx) => (
              <div
                key={day.date}
                className={classNames(
                  dayIdx > 6 && "border-t border-gray-200",
                  "py-2"
                )}
              >
                {day.visible && day.selectable ? (
                  <button
                    onClick={() => updateNote(day.date)}
                    type="button"
                    className={classNames(
                      day.isSelected && "text-white",
                      !day.isSelected && day.isToday && "text-sky-600",
                      !day.isSelected &&
                        !day.isToday &&
                        day.isCurrentMonth &&
                        "text-gray-900",
                      !day.isSelected &&
                        !day.isToday &&
                        !day.isCurrentMonth &&
                        "text-gray-400",
                      day.isSelected && day.isToday && "bg-sky-600",
                      day.isSelected && !day.isToday && "bg-gray-900",
                      !day.isSelected && "hover:bg-gray-200",
                      (day.isSelected || day.isToday) && "font-semibold",
                      "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                    )}
                  >
                    <time dateTime={day.date}>
                      {day.date.split("-").pop().replace(/^0/, "")}
                    </time>
                  </button>
                ) : (
                  <div
                    className={classNames(
                      "mx-auto flex h-8 w-8 items-center justify-center rounded-full cursor-default",
                      (day.isSelected || day.isToday) && "font-semibold",
                      day.isHoliday ? "text-red-500 font-bold" : "",
                      day.isToday ? "text-sky-600" : "opacity-50  "
                    )}
                  >
                    <time dateTime={day.date}>
                      {day.date.split("-").pop().replace(/^0/, "")}
                    </time>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="text-center p-3 py-5">
            Čas dodání: <strong>16 - 20 h</strong>
          </div>
          {selectedDay && (
            <button
              type="submit"
              className="flex items-center w-full mb-5 justify-center  text-sky-100 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-sky-600 rounded-md border border-gray-200 hover:bg-sky-700"
              onClick={() => updateNote(format(selectedDay, "yyyy-MM-dd"))}
            >
              <CheckIcon className="w-8 " />
              <span>Potvrdit</span>
            </button>
          )}
        </div>
      </ModalLayout>
    )
  }

  function chooseDate() {
    dispatch({
      type: "SET_MODAL",
      payload: {
        component: DateSelect,
        fullscreen: true,
      },
    })
  }

  return (
    <>
      <div className="p-2 px-4 bg-white mt-2 text-sm">
        {transportNote ? (
          <div className={"space-x-2 flex " + (summary && "text-xs")}>
            <span>{transportNote?.name}</span>
            {!summary && (
              <a
                className="underline hover:no-underline"
                onClick={() => chooseDate()}
              >
                Změnit
              </a>
            )}
          </div>
        ) : (
          <div>
            <a
              className="underline hover:no-underline"
              onClick={() => chooseDate()}
            >
              Zvolit datum dodání
            </a>
          </div>
        )}

        {!summary && (
          <>
            <input
              type="hidden"
              {...register("rozvozDate", { required: true })}
              defaultValue={transportNote?.name}
            />
            {errors.rozvozDate?.type === "required" && !transportNote?.date && (
              <Error text={"Zvolte prosím datum dodání"} />
            )}
          </>
        )}
      </div>
    </>
  )
}
